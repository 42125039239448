import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 最佳义警列表
const bestMember = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/best/member', {params})
}

// 今日最佳协会
const bestOrg = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/best/org', {params})
}

// 今日最佳队伍
const bestTeam = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/best/team', {params})
}

// 义警积分明细
const bestLoveValue = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/best/love_value', {params})
}

// 义警作弊举报
const bestReport = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/best/report', params)
}

// 最佳义警详情
const bestDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/best/member/one', {params})
}



export default {
	bestMember,
	bestOrg,
	bestTeam,
	bestLoveValue,
	bestReport,
	bestDetail
}
