<template>
	<div class="BestPeopleList">
		<w-navTab titleText="今日义警排行"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="content">
			<div class="header-title">
				<div class="header-title-left">
					共{{memberList.length}}名
				</div>
				<div class="header-title-right" @click="isBestShow = true">
					<van-icon style="margin-right: 2px;" name="question-o" color="#3377FF" size="16" />排名规则
				</div>
			</div>
			<div class="body">
				<div class="body-list">
					<div class="body-list-item" v-for="(item,index) in memberList" @click="$router.push({ name: 'BestPeopleDetail', params:{ id: item.user_id, total: index + 1 ,district_id: item.district_id}})">
						<div class="body-item-left">
							<img :src="item.avatar" alt="">
							<div class="left-img-sort">{{index + 1}}</div>
						</div>
						<div class="body-item-right">
							<div class="body-right-name">{{item.name}}</div>
							<div class="body-right-text">{{item.district_txt}} {{item.level_text}}</div>
							<div class="body-right-footer">
								<div class="right-footer-number">{{item.total_love_value}}爱心值</div>
								<div class="right-footer-text">爱心值明细 <van-icon name="arrow" /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="isBestShow" :round="true">
			<div class="popup-bestList">
				<div class="popup-content">
					<div class="popup-title">计算方式：</div>
					<div class="popup-text">
						义警当日获得的爱心值总数（含签到、打卡等）
					</div>
					<div class="popup-title">排序方式：</div>
					<div class="popup-text">
						根据爱心值由大到小排列
					</div>
				</div>
				<div class="popup-footer" @click="isBestShow = false">我知道了</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import LoadingPage from '@/components/LoadingPage';
	import Best from '@/api/best';
	
	export default {
		name: 'BestPeopleList',
		data() {
			return {
				isBestShow: false,
				loadingPage: true,
				memberList: [],
				limit: 10
			}
		},
		created() {
			this.getBestMember()
		},
		methods: {
			/**
			 * 最佳义警列表
			 */
			getBestMember(){
				
				let params = {
					limit: this.limit
				}
				
				Best.bestMember(params)
					.then(result => {
						this.memberList = result.data
						this.loadingPage = false
					}, error => {
						console.log(error)
					})
			},
		},
		components:{
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.BestPeopleList {
		padding-bottom: 20px;
		box-sizing: border-box;
		.content{
			.header-title{
				padding: 20px 16px 16px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				.header-title-left{
					color: #000;
					font-size: 16px;
					line-height: 24px;
				}
				.header-title-right{
					display: flex;
					align-items: center;
					color: #999;
					font-size: 13px;
					line-height: 20px;
				}
			}
			.body{
				.body-list{
					padding: 0 16px;
					box-sizing: border-box;
					
					.body-list-item{
						border-radius: 8px;
						background: #FFF;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 12px 12px 12px 16px;
						box-sizing: border-box;
						margin-bottom: 12px;
						
						&.body-list-item:last-child{
							margin-bottom: 0;
						}
						
						.body-item-left{
							margin-right: 12px;
							width: 80px;
							height: 80px;
							position: relative;
							
							img{
								width: 100%;
								height: 100%;
								border-radius: 4px;
								object-fit: cover;
							}
							.left-img-sort{
								position: absolute;
								top: 0;
								left: 0;
								width: 24px;
								height: 24px;
								border-radius: 4px 0px;
								background: #FF6969;
								color: #FFF;
								text-align: center;
								font-size: 14px;
								line-height: 24px;
							}
						}
						.body-item-right{
							flex: 1;
							.body-right-name{
								color: #222;
								font-size: 18px;
								line-height: 27px;
							}
							.body-right-text{
								margin: 4px 0 7px;
								color: #777;
								font-size: 14px;
								line-height: 21px;
							}
							.body-right-footer{
								display: flex;
								justify-content: space-between;
								align-items: center;
								
								.right-footer-number{
									color: #FF6969;
									font-size: 14px;
									line-height: 21px;
								}
								.right-footer-text{
									color: #AAAAA9;
									font-size: 12px;
									line-height: 21px;
								}
							}
						}
					}
				}
			}
		}
		.popup-bestList{
			width: 292px;
			background: #FFF;
			
			.popup-content{
				padding: 0 27px;
				box-sizing: border-box;
				
				.popup-title{
					padding-top: 27px;
					color: #666;
					font-size: 16px;
					font-weight: bold;
					line-height: 24px;
					box-sizing: border-box;
				}
				.popup-text{
					color: #666;
					font-size: 14px;
					line-height: 21px;
					margin-top: 5px;
				}
			}
		
			.popup-footer{
				border-top: 1px solid #eee;
				padding: 10px 0 12px;
				text-align: center;
				color: #37F;
				font-size: 16px;
				line-height: 22px;
				box-sizing: border-box;
				margin-top: 19px;
			}
		}
	}
</style>
